$tmo_magenta: #e20074;
$tmo_gray: #858585;
$white_themes: #ffffff;
$black_themes: #000000;
$tmo_fontRegular: 'TeleGroteskNextRegular';
$smallmobile: 468px;
$mobileSize: 768px; //375 - 767
$tabletSize: 1024px; //768 - 1023
$laptopSize: 1440px; //1024 - 1440

.commonFlowHeader {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 27px;
  color: $black_themes;
  font-style: normal;
  @media screen and (max-width: $smallmobile) {
    font-size: 18px;
  }
}

.common_button {
  box-sizing: border-box;
  padding: 0 36px;
  font-style: normal;
  color: $white_themes;
  line-height: 16px;
  background: $tmo_magenta;
  border: 1px solid $tmo_magenta;
  border-radius: 5px;
  cursor: pointer;
  width: 8rem;
}

.common_disabled_button {
  box-sizing: border-box;
  padding: 0 36px;
  font-style: normal;
  color: $white_themes;
  line-height: 16px;
  background: $tmo_gray;
  border: 1px solid $tmo_gray;
  border-radius: 5px;
  cursor: pointer;
  width: 8rem;
}

.error {
  color: #ff1010;
}
@-webkit-keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.spinner {
  /* height: 100vh; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner__circle {
  position: relative;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 100%;
  background-image: conic-gradient(transparent, #f5f5f5);
  -webkit-animation: spin 1.25s infinite linear;
  animation: spin 1.25s infinite linear;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner__circle_inner {
  width: 25px;
  height: 25px;
  background-color: $tmo_magenta;
  border-radius: 100%;
}
.spinner__circle_dot {
  position: absolute;
  top: 0%;
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
  border-radius: 50%;
}
