body {
  margin: 0;
  font-family: TeleGroteskNextRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // background-color: #F5F5F5;
  background-color: white;
  box-sizing: border-box;
  height:100%;
  width: 100%;
  // min-width: 1024px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursor-pointer {
  cursor: pointer;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(224, 226, 232);
  -webkit-box-shadow: inset 0 0 6px rgba(224, 226, 232);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

@font-face {
  font-family: "TeleGroteskNextRegular";
  src: local("TeleGroteskNextRegular"), url(./assets/telegrotesk-next/TeleGroteskNextRegular.ttf) format("truetype");
  font-weight: normal;
}
