@use '../common/style.module.scss' as styles;

.navBar {
  margin: auto;
  margin-top: 80px;
}

.headerStyle {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  background-color: styles.$white_themes;
  height: 3rem;
  width: 100%;
  margin: auto;
  border-color: navy;
  z-index: 9999;
  // box-shadow: 0px 4px 20px rgba(144, 157, 193, 0.2);
}
.headerStyle img {
  height: 2.5vw;

  @media screen and (max-width: '767px') {
    height: 25px;
  }
}
.headerText {
  color: styles.$tmo_magenta;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  letter-spacing: 0.03rem;
  font-family: styles.$tmo_fontRegular, sans-serif;
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 12px;
  cursor: pointer;

  @media screen and (max-width: '767px') {
    line-height: 1;
    font-size: 14px;
  }
}

.headerLayout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 24px;

  @media screen and (max-width: '767px') {
    padding: 0 16px;
  }
}
.headerEmail {
  padding: 0.5rem 1rem;
  text-transform: capitalize;
  display: inline-block;

  @media screen and (max-width: '767px') {
    display: none;
  }
}

@-webkit-keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner__circle {
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 100%;
  background-image: conic-gradient(transparent, styles.$tmo_magenta);
  -webkit-animation: spin 1.25s infinite linear;
  animation: spin 1.25s infinite linear;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner__circle_inner {
  width: 50px;
  height: 50px;
  background-color: #f5f5f5;
  border-radius: 100%;
}
.spinner__circle_dot {
  position: absolute;
  top: 0%;
  width: 5px;
  height: 5px;
  background-color: styles.$tmo_magenta;
  border-radius: 50%;
}

.burgerMenuImg {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.burgerSubMenu {
  position: absolute;
  top: 60px;
  left: calc(100% - 270px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;

  width: 204px;
  height: 90px;

  background: #ffffff;
  border: 1px solid #dee2e9;
  border-radius: 5px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.headerSubmenuSeparator {
  position: relative;
  width: 80%;
  left: 0px;
}
hr {
  border: 0;
  border-top: 1px solid #dee2e9;
}

.contactCTP,
.signOut {
  cursor: pointer;
  text-decoration: none;
  color: styles.$tmo_magenta;
}
